// src/App.js
import React from 'react';
import './App.css';
import TextProcessor from './components/TextProcessor.jsx';

function App() {
  return (
    <div>
      <header>
        <div className="App">
          <TextProcessor />
        </div>
      </header>
    </div>
  );
}

export default App;
