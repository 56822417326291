// src/TextProcessor.js
import React, { useState } from 'react';

const TextProcessor = () => {
    const [inputText, setInputText] = useState('');
    const [outputText, setOutputText] = useState('');
    const [fileCount, setFileCount] = useState(0);

    const processText = () => {
        let outputData = '';
        let cntList = [];
        let contentList = [];
        let data;

        try {
            data = JSON.parse(inputText)["result"]; // Assuming input is valid JSON
        } catch (error) {
            alert("Invalid JSON input. Please check your input.");
            return;
        }

        let cnt = 0;
        data.forEach(question => {
            cnt++;
            let questionContent = question.examQuestionVo.content;
            if (contentList.includes(questionContent)) return;
            contentList.push(questionContent);
            let cntThis = 1; // Each question counts as one

            let outputToAdd = '';
            let id = 'A';
            let correct = '';
            const options = question.examQuestionVo.examQuestionOptionVos;

            options.forEach(option => {
                if (option.correct) correct += id;
                outputToAdd += `${id} ${option.content}\n`;
                id = String.fromCharCode(id.charCodeAt(0) + 1);
            });

            questionContent = questionContent.replace('（', '(').replace('）', ')');
            if (!questionContent.includes('(') || !questionContent.includes(')')) {
                questionContent += '()';
            }
            let lastOpenParenIndex = questionContent.lastIndexOf('(');
            let lastCloseParenIndex = questionContent.lastIndexOf(')');

            if (lastOpenParenIndex !== -1 && lastCloseParenIndex !== -1 && 
                questionContent.substring(lastOpenParenIndex + 1, lastCloseParenIndex).trim() !== '') {
                questionContent += '()';
            }
            lastOpenParenIndex = questionContent.lastIndexOf('(');
            lastCloseParenIndex = questionContent.lastIndexOf(')');

            questionContent = `${questionContent.substring(0, lastOpenParenIndex + 1)}${correct}${questionContent.substring(lastCloseParenIndex)}`;

            outputData += `${cnt}. ${questionContent}\n${outputToAdd}解析: ${question.examQuestionVo.description.replace('\n', '')}\n\n`;
            cntList.push(cntThis);
        });

        setOutputText(outputData);
        setFileCount(cntList.length);
    };

    return (
        <div>
            <h2>Input Text</h2>
            <textarea
                rows="20"
                cols="150"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                placeholder="Paste your full response from findQuestionsByActivityId here..."
            />
            <br />
            <button onClick={processText}>Process Text</button>
            <h3>Output:</h3>
            <textarea
                rows="20"
                cols="150"
                value={outputText}
                readOnly
            />
            <p>Questions: {fileCount}</p>
        </div>
    );
};

export default TextProcessor;
